import React from 'react'
import Container from 'react-bootstrap/Container';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import QuickLinks from '../components/quicklinks';
import ContactForm from '../components/contactform';

import francisimg from '../img/francis.png'
class AboutPage extends React.Component {
    render() {
        return (
            <>
            <div className="about-main-display about-mobile-header-img">
                <div className="home-main-display-text">
                    <h1>About Us</h1>
                </div>
            </div>
            <Container className="about-francis">
                <Row className="about-francis-row">
                    <Col className="about-francis-text">
                        <p>
                        My name is Francis Maloney and I own and operate Emerald Detailing. I have over a decade of detailing experience, working on some of the most valuable cars in the world. I specialise in working on supercars and prestige vehicles. I also happen to be the most experienced Aston Martin detailer in the country. I have clients all over the country, so I could be in north Yorkshire one week and then Essex the next. My widespread client base is one of the reasons that I have stayed mobile and not set up a unit. I believe that my clients time is valuble, so rather than waste half a day bringing your car to me, I just go to them instead.
                        </p>
                        <p>
                        Emerald detailing was built of the back of giving people advice on how to look after their cars and to this day, should anyone have any questions about car care, I’m only a phone call or email away. There are so many cases of car owners being ripped off or not getting what they think they are paying for, so I’ve made sure the client always knows exactly what they are getting, without trying to baffle with fancy phrases or brand names.
                        </p>
                        <p>
                        I am authorised by a Gtechniq, Titan Coatings and Kamikaze. I strive to give clients the best, so I am always trying new products and making sure that whatever I use is the best at what it does.
                        </p>
                    </Col>
                    <Col className="about-francis-img">
                        <img src={francisimg} alt="image-of-francis" height="70%"></img>
                    </Col>
                </Row>
            </Container>
            <Container fluid={true} className="about-aston-martin">
                <div className="about-aston-martin-content">
                <h2 className="about-aston-martin-heading">Aston Martin Specialist</h2>
                <br />
                <p className="about-aston-martin-text">
                I am the most experienced Aston Martin detailer outside of the dealer network and have seen all sorts of things over the years. Because of this, I know what areas to be extra careful with when machine polishing and where to look for areas of poor paint finishing from the factory.
                </p>
                <p className="about-aston-martin-text">
                Please contact me for any specific Aston Martin enquiries
                </p>
                <br />
                <a href="/services">
                <button className="mainbutton">LEARN MORE</button>
                </a>
                </div>
            </Container>
            <QuickLinks />
            <div className="contact-form-home">
            <ContactForm />
            </div>
            </>
        );
    }
}

export default AboutPage