import React, { Fragment } from 'react';
import { Switch, Route } from "react-router-dom";

import NavBar from './components/navbar'
import Footer from './components/footer'

import HomePage from './pages/HomePage'
import AboutPage from './pages/AboutPage'
import ServicesPage from './pages/ServicesPage'
import TestemonialPage from './pages/TestemonialPage'
import ContactPage from './pages/ContactPage'
import FaqPage from './pages/FaqPage'
import GalleryPage from './pages/GalleryPage';
import CeramicCoatingPage from './pages/CeramicCoatingPage';
import PageNotFound from './pages/PageNotFound';


import 'react-image-lightbox/style.css'
import './App.css';


function App() {
  return (
    <Fragment>
      <NavBar />
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route path="/about">
          <AboutPage />
        </Route>
        <Route path="/services">
          <ServicesPage />
        </Route>
        <Route path="/testimonials">
          <TestemonialPage />
        </Route>
        <Route path="/contact">
          <ContactPage />
        </Route>
        <Route path="/faq">
          <FaqPage />
        </Route>
        <Route path="/gallery">
          <GalleryPage />
        </Route>
        <Route path="/ceramic-coating">
          <CeramicCoatingPage />
        </Route>
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>

      <Footer />
    </Fragment>
  );
}

export default App;
