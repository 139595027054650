import React from 'react'
import $ from 'jquery'

import { Card, Container } from 'react-bootstrap';

import BeforeImg from '../img/Before.png'
import AfterImg from '../img/After.png'

import QuickLinks from '../components/quicklinks';
import ContactForm from '../components/contactform';
class ServicesPage extends React.Component {
    
    hide1() {
        $('#hide1').toggle();
        $('#hide1btn').toggleClass('show-less-button')
    }
    
    hide2() {
        $('#hide2').toggle();
        $('#hide2btn').toggleClass('show-less-button')
    }

    hide3() {
        $('#hide3').toggle();
        $('#hide3btn').toggleClass('show-less-button')
    }

    render() {
        return (
            <>
            <div className="services-main-display services-mobile-header-img">
                <div className="home-main-display-text">
                    <h1>Services</h1>
                </div>
            </div>
            <Container fluid={true} className="services-intro-container">
                <div className="services-intro-text">
                    <h2>
                    Services and Process
                    </h2>
                    <br />
                    <p>
                    I specialise in paint correction (machine polishing), but no matter the service you are looking for, the aim is still the same. To thoroughly clean, decontaminate and make sure the car looks as good as it can.
                    </p>
                    <br />
                    <p>
                    An example of paint correction, which can transform the look of your car:
                    </p>
                </div>
                <div className="services-before-after">
                    <div className="services-b-a d-inline-block">
                    <p>Before</p>
                    <img className="services-b-a-img" src={BeforeImg} alt="before" width="178px" height="178px"></img>
                    </div>
                    <div className="services-b-a d-inline-block">
                    <p>After</p>
                    <img className="services-b-a-img" src={AfterImg} alt="after" width="178px" height="178px"></img>
                    </div>
                </div>
                <div className="service-intro-text" >
                    <p>
                    No matter what option you decide to go for, the vehicle will receive a full decontamination process before beginning your chosen procedure.
                    </p>
                </div>
            </Container>
            <Container className="services-card-carousel">
                <Card className="h-card">
                        <Card.Body className="h-card-top">
                            <h4>Protection Detail</h4>
                            <h2>£150</h2>
                            <p>For those who want a quick fix</p>
                        </Card.Body>
                        <Card.Body className="h-card-bottom">
                            <p>After the wash stage detailed above, the vehicle is waxed using a high grade carnauba wax or spray sealant. The interior is also detailed and leather cleaned.</p>
                        </Card.Body>
                        <a href="/contact">
                        <button className="submit-button services-book-button">BOOK NOW</button>
                        </a>
                </Card>
                <Card className="h-card">
                        <Card.Body className="h-card-top">
                            <h4>Enhancement Detail</h4>
                            <h2>£350</h2>
                            <p>For those on a budget</p>
                        </Card.Body>
                        <Card.Body className="h-card-bottom">
                            <p>This option is suited to those with new vehicles or for those on a budget, but still want to add depth and clarity to their vehicles paintwork. </p>
                            <p id ="hide1">This option includes a single stage machine polish. This will remove wash marring and the lighter swirl marks. It will be like removing a grey film from the Vehicles paintwork, allowing the colour to come through.</p>
                            <button id="hide1btn" className="show-more-button" onClick={this.hide1.bind(this)}></button>
                        </Card.Body>
                        <a href="/contact">
                        <button className="submit-button services-book-button">BOOK NOW</button>
                        </a>
                </Card>
                <Card className="h-card">
                        <Card.Body className="h-card-top card-special">
                            <h4>2-Day Light Correction Detail</h4>
                            <h2>£700</h2>
                            <p>Most Popular</p>
                        </Card.Body>
                        <Card.Body className="h-card-bottom">
                            <p>This detail is the most popular option, and in most cases, enough to get rid of all the swirl marks and most of the deeper marks in the paint. This is possible due to the extra days machine polishing.</p>
                        </Card.Body>
                        <a href="/contact">
                        <button className="submit-button services-book-button">BOOK NOW</button>
                        </a>
                </Card>
            </Container>
            <div className="services-premium-title">
                <h3>Premium Services</h3>
            </div>
            <Container fluid={true} className="services-premium">
                <Card className="h-card-style2">
                        <Card.Body className="h-card-top-style2 card-style2">
                            <h4>Full Correction Detail</h4>
                            <h2>£1050 - £1400</h2>
                            <p>For those who want the best</p>
                        </Card.Body>
                        <Card.Body className="h-card-bottom card-style2">
                            <p>This is for those that want the best for their car and for the paintwork to be as good as it can be. All marks in the paint that are safe to remove will be polished out, leaving the surface as defect free as possible.</p>
                            <div id="hide2">
                                <br />
                                <p>This option is perfect for hand finished cars such as Aston Martin, Ferrari and Mclaren, who’s cars are sanded and polished as part of the paint process to remove the orange the texture in the vehicles paintwork. The trouble is, they are given a limited time to complete each car, so more often than not, patches are missed that require machine polishing to remove. This option is also very good for large cars, such as Rolls Royce, Bentley and Range rover.</p>
                                <br />
                                <p>The vehicle will be waxed or have a spray sealant applied for free as part of the detail, but if you wish to have longer lasting protection, then a ceramic coating is available as an added cost option.</p>
                                <br />
                                <p>The exhausts and glass will be cleaned and tyres dressed following the completion of the detail chosen.</p>
                            </div>
                            <button id="hide2btn" className="show-more-button-style2" onClick={this.hide2.bind(this)}></button>
                        </Card.Body>
                        <a href="/contact">
                        <button className="submit-button services-book-button">BOOK NOW</button>
                        </a>
                </Card>
                <Card className="h-card-style2">
                        <Card.Body className="h-card-top-style2 card-style2">
                            <h4>Emerald Ultimate Detail</h4>
                            <h2>£POA</h2>
                            <p>For those where budget isn't an issue</p>
                        </Card.Body>
                        <Card.Body className="h-card-bottom card-style2">
                            <p>This is a service for those that want the best and where budget isn’t an issue. Everything is included and it takes as long as it takes to get the car looking as good as it possibly can. Since everything will be protected, it will be easy to keep the car looking fantastic going forward.</p>
                            <div id="hide3">
                                <br />
                                <p>What’s included:</p>
                                <br />
                                <p>Full wash and decontamination process.</p>
                                <br />
                                <p>Engine bay cleaned and dressed </p>
                                <br />
                                <p>Wheels off. Full deep clean of wheels, calipers, visible suspension components and arches.</p>
                                <br />
                                <p>Wheels and calipers protected with Kamikaze Stance rim coat.</p>
                                <br />
                                <p>Full correction detail (95%+ defect removal)</p>
                                <br />
                                <p>Any minor stone chips filled in.</p>
                                <br />
                                <p>Full interior clean and protect with fabric and leather coatings</p>
                                <br />
                                <p>Pro grade ceramic coating (Multiple layers available upon request)</p>
                                <br />
                                <p>Glass machine polished and protected</p>
                                <br />
                                <p>Any and all brightwork polished</p>
                                <br />
                                <p>Any corroded wheel nuts or trim screws either painted or replaced</p>
                                <br />
                                <p>This service is great for those that want the best for their car and to keep it in concours like condition, but also for those that want to use their cars. Since everything will be protected, it will be easy to keep the car looking fantastic going forward.</p>
                                <br />
                                <p>Inspection of the car is required before any work can be carried out. </p>
                                <br />
                                <p>The process usually takes 5 or more days to complete.</p>
                            </div>
                            <button id="hide3btn" className="show-more-button-style2" onClick={this.hide3.bind(this)}></button>
                        </Card.Body>
                        <a href="/contact">
                        <button className="submit-button services-book-button">BOOK NOW</button>
                        </a>
                </Card>
            </Container>
            <Container fluid={true} className="services-ceramic-coating">
                <div className="services-ceramic-coating-text">
                    <h2>
                    Ceramic Coating Options
                    </h2>
                    <br />
                    <p>
                    I will only use the best coatings offered by a manufacturer. I am not tied to one manufacturer and am always looking at new and updated coatings to make sure I give my clients the best.
                    </p>
                    <br />
                    <p>
                    I offer a range of different ceramic coating options depending on the specific job to ensure the best treatment for you.
                    </p>
                    <a href="/ceramic-coating">
                        <button className="mainbutton">SEE OPTIONS</button>
                    </a>
                </div>
            </Container>
            <QuickLinks />
            <div className="contact-form-home">
            <ContactForm />
            </div>
            </>
        );
    }
}

export default ServicesPage