import React from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

import FacebookImg from '../img/facebook-1440.png'
import InstaImg from '../img/insta-1440.png'
import TwitterImg from '../img/twitter-1440.png'
import SMLogo from '../img/colour-logo.svg'

class Footer extends React.Component {
    render() {
        return(
            <Container fluid={true} className="footer-main">
                <div className="overlay"></div>
                <div className="footer-row-top">
                    <Row>
                        <Col className="footer-links">
                            <a href="/" className="footer-link">HOME</a>
                            <a href="/about" className="footer-link">ABOUT US</a>
                            <a href="/services" className="footer-link">SERVICES</a>
                            <a href="/gallery" className="footer-link">GALLERY</a>
                            <a href="/testimonials" className="footer-link">TESTIMONIALS</a>
                            <a href="/contact" className="footer-link">CONTACT</a>
                            <a href="/faq" className="footer-link">FAQ'S</a>
                        </Col>
                        <Col className="social-links">
                            <p>FOLLOW US</p>
                            <a target="_blank" href="https://www.instagram.com/emeralddetailing/?hl=en">
                            <img src={InstaImg} height="40px" alt="instagram" className="social-link"></img>
                            </a>
                            <a target="_blank" href="https://www.facebook.com/EmeraldDetailing/">
                            <img src={FacebookImg} height="40px" alt="facebook" className="social-link"></img>
                            </a>     
                        </Col>
                    </Row>
                </div>
                <div className="footer-row-bottom">
                    <Row>
                        <Col className="mwl-col">
                            <p className="made-with-love d-inline-block">Made with love by </p>
                            <a href="https://spicymango.co.uk"><img src={SMLogo} alt="spicymango-logo" height="28px" className="sm-logo d-inline-block"/></a>
                        </Col>
                    </Row>
                </div>
            </Container>
        );
        }
}

export default Footer
