import { React, useState } from 'react'
import { Link } from 'react-router-dom'

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

import Logo from '../img/logo-1440.svg'

const NavBar = () => {

      const [expanded, setExpanded] = useState(false);

      const expandNav = () => {
        if (window.innerWidth < 960) { 
          setExpanded(expanded ? false : "expanded")
        }
      }

      return (
        <Navbar className="navbar-default" expand="lg" variant="dark" expanded={expanded} >
        <Navbar.Brand href="/"><img src={Logo} className="logo" alt=""></img></Navbar.Brand> 
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => expandNav()}/>
        {/* <Container fluid={true} className="nav-container" id="hideable"> */}
          <Navbar.Collapse className="navbar-main-links float-right" id="basic-navbar-nav">
          {/* <div id="hideable" class="open"> */}
            <Nav style={{ width: "100%" }}>
              <Nav.Link as={Link} to={"/"} onClick={() => expandNav()} >HOME</Nav.Link>
              <Nav.Link as={Link} to={"/about"} onClick={() => expandNav()} >ABOUT US</Nav.Link>
              <Nav.Link as={Link} to={"/services"} onClick={() => expandNav()} >SERVICES</Nav.Link>
              <Nav.Link as={Link} to={"/gallery"} onClick={() => expandNav()} >GALLERY</Nav.Link>
              <Nav.Link as={Link} to={"/testimonials"} onClick={() => expandNav()} >TESTIMONIALS</Nav.Link>
              <Nav.Link as={Link} to={"/contact"} onClick={() => expandNav()} >CONTACT</Nav.Link>
              <Nav.Link as={Link} to={"/faq"} onClick={() => expandNav()} >FAQ'S</Nav.Link>
              <div className="mobile-nav-contact">
              <Navbar.Text>
              <p className="small-text-style-1 d-inline">CALL</p>&nbsp;
              <p className="small-text-style-2 d-inline"><a href="tel:07909541048">07909 541048</a></p>&nbsp;
              </Navbar.Text>
              <Navbar.Text>
              <p className="small-text-style-1 d-inline">EMAIL</p>&nbsp;
                <p className="small-text-style-2 d-inline"><a href="mailto:hello@emerald-detailing.com">HELLO@EMERALD-DETAILING.COM</a></p>
              </Navbar.Text>
              </div>
            </Nav>
          {/* </div> */}
            {/* <button id="button" className="hide-nav" onClick={this.hideNav.bind(this)}><img src={menuimg} alt="hamburger-nav"></img></button> */}
          </Navbar.Collapse>
          {/* </Container> */}
        </Navbar>
      );
    }

export default NavBar