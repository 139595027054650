import React from 'react'
import Lightbox from 'react-image-lightbox'

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { Container, Row, Col } from 'react-bootstrap'

const dir = 'https://emerald-detailing.s3.eu-west-1.amazonaws.com'

const images = [
     dir + "/gallery/Ferrari/Ferrari_1.jpg",
     dir + "/gallery/Ferrari/Ferrari_2.jpg",
     dir + "/gallery/Ferrari/Ferrari_3.jpg",
     dir + "/gallery/Ferrari/Ferrari_4.jpg",
     dir + "/gallery/Ferrari/Ferrari_5.jpg",
     dir + "/gallery/Ferrari/Ferrari_6.jpg",
     dir + "/gallery/Ferrari/Ferrari_7.jpg",
     dir + "/gallery/Ferrari/Ferrari_8.jpg",
     dir + "/gallery/Ferrari/Ferrari_9.jpg",
     dir + "/gallery/Ferrari/Ferrari_10.jpg",
     dir + "/gallery/Ferrari/Ferrari_11.jpg",
     dir + "/gallery/Ferrari/Ferrari_12.jpg",
     dir + "/gallery/Ferrari/Ferrari_13.jpg",
     dir + "/gallery/Ferrari/Ferrari_14.jpg",
     dir + "/gallery/Ferrari/Ferrari_15.jpg",
     dir + "/gallery/Ferrari/Ferrari_16.jpg",
     dir + "/gallery/Ferrari/Ferrari_17.jpg",
     dir + "/gallery/Ferrari/Ferrari_18.jpg",
     dir + "/gallery/Ferrari/Ferrari_19.jpg",
     dir + "/gallery/Ferrari/Ferrari_20.jpg",
     dir + "/gallery/Ferrari/Ferrari_21.jpg",
     dir + "/gallery/Ferrari/Ferrari_22.jpg",

     dir + "/gallery/Aston/Aston_1.jpg",
     dir + "/gallery/Aston/Aston_2.jpg",
     dir + "/gallery/Aston/Aston_3.jpg",
     dir + "/gallery/Aston/Aston_4.jpg",
     dir + "/gallery/Aston/Aston_5.jpg",
     dir + "/gallery/Aston/Aston_6.jpg",
     dir + "/gallery/Aston/Aston_7.jpg",
     dir + "/gallery/Aston/Aston_8.jpg",
     dir + "/gallery/Aston/Aston_9.jpg",
     dir + "/gallery/Aston/Aston_10.jpg",
     dir + "/gallery/Aston/Aston_11.jpg",
     dir + "/gallery/Aston/Aston_12.jpg",
     dir + "/gallery/Aston/Aston_13.jpg",
     dir + "/gallery/Aston/Aston_14.jpg",
     dir + "/gallery/Aston/Aston_15.jpg",
     dir + "/gallery/Aston/Aston_16.jpg",
     dir + "/gallery/Aston/Aston_17.jpg",
     dir + "/gallery/Aston/Aston_18.jpg",
     dir + "/gallery/Aston/Aston_19.jpg",
     dir + "/gallery/Aston/Aston_20.jpg",
     dir + "/gallery/Aston/Aston_21.jpg",
     dir + "/gallery/Aston/Aston_22.jpg",
     dir + "/gallery/Aston/Aston_23.jpg",
     dir + "/gallery/Aston/Aston_24.jpg",
     dir + "/gallery/Aston/Aston_25.jpg",
     dir + "/gallery/Aston/Aston_26.jpg",
     dir + "/gallery/Aston/Aston_27.jpg",
     dir + "/gallery/Aston/Aston_28.jpg",
     dir + "/gallery/Aston/Aston_29.jpg",
     dir + "/gallery/Aston/Aston_30.jpg",
     dir + "/gallery/Aston/Aston_31.jpg",

     dir + "/gallery/Mclaren/Mclaren_1.jpg",
     dir + "/gallery/Mclaren/Mclaren_2.jpg",
     dir + "/gallery/Mclaren/Mclaren_3.jpg",
     dir + "/gallery/Mclaren/Mclaren_4.jpg",
     dir + "/gallery/Mclaren/Mclaren_5.jpg",
     dir + "/gallery/Mclaren/Mclaren_6.jpg",
     dir + "/gallery/Mclaren/Mclaren_7.jpg",
     dir + "/gallery/Mclaren/Mclaren_8.jpg",
     dir + "/gallery/Mclaren/Mclaren_9.jpg",

     dir + "/gallery/Porshe/Porshe_1.jpg",
     dir + "/gallery/Porshe/Porshe_2.jpg",
     dir + "/gallery/Porshe/Porshe_3.jpg",
     dir + "/gallery/Porshe/Porshe_4.jpg",
     dir + "/gallery/Porshe/Porshe_5.jpg",
     dir + "/gallery/Porshe/Porshe_6.jpg",
     dir + "/gallery/Porshe/Porshe_7.jpg",
     dir + "/gallery/Porshe/Porshe_8.jpg",
     dir + "/gallery/Porshe/Porshe_9.jpg",
     dir + "/gallery/Porshe/Porshe_10.jpg",
     dir + "/gallery/Porshe/Porshe_11.jpg",
     dir + "/gallery/Porshe/Porshe_12.jpg",
     dir + "/gallery/Porshe/Porshe_13.jpg",
     dir + "/gallery/Porshe/Porshe_14.jpg",
     dir + "/gallery/Porshe/Porshe_15.jpg",
     dir + "/gallery/Porshe/Porshe_16.jpg",
     dir + "/gallery/Porshe/Porshe_17.jpg",
     dir + "/gallery/Porshe/Porshe_18.jpg",

     dir + "/gallery/Misc/Misc_1.jpg",
     dir + "/gallery/Misc/Misc_2.jpg",
     dir + "/gallery/Misc/Misc_3.jpg",
     dir + "/gallery/Misc/Misc_4.jpg",
     dir + "/gallery/Misc/Misc_5.jpg",
     dir + "/gallery/Misc/Misc_6.jpg",
     dir + "/gallery/Misc/Misc_7.jpg",
     dir + "/gallery/Misc/Misc_8.jpg",
     dir + "/gallery/Misc/Misc_9.jpg",
     dir + "/gallery/Misc/Misc_10.jpg",
     dir + "/gallery/Misc/Misc_11.jpg",
     dir + "/gallery/Misc/Misc_12.jpg",
     dir + "/gallery/Misc/Misc_13.jpg",
     dir + "/gallery/Misc/Misc_14.jpg",
     dir + "/gallery/Misc/Misc_15.jpg",
     dir + "/gallery/Misc/Misc_16.jpg",
     dir + "/gallery/Misc/Misc_17.jpg",
     dir + "/gallery/Misc/Misc_18.jpg",
     dir + "/gallery/Misc/Misc_19.jpg",
     dir + "/gallery/Misc/Misc_20.jpg",
     dir + "/gallery/Misc/Misc_21.jpg",
     dir + "/gallery/Misc/Misc_22.jpg",
     dir + "/gallery/Misc/Misc_23.jpg",
     dir + "/gallery/Misc/Misc_24.jpg",
     dir + "/gallery/Misc/Misc_25.jpg"
 ];

class GalleryPage extends React.Component {

    constructor(props) {
        super(props);
    
        this.state = {
          photoIndex: 0,
          isOpen: false,
        };
      }

    render() {

        const { photoIndex, isOpen } = this.state;

        return (
            <>
            <div className="gallery-main-display gallery-mobile-header-img">
                <div className="home-main-display-text">
                    <h1>Gallery</h1>
                </div>
            </div>
            <Container>
            <Tabs
            defaultActiveKey="ferrari"
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
            >
            <Tab eventKey="ferrari" title="FERRARI" className="gallery-tab">
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[0]} onClick={() => this.setState({ isOpen: true, photoIndex:0 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[1]} onClick={() => this.setState({ isOpen: true, photoIndex:1 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[2]} onClick={() => this.setState({ isOpen: true, photoIndex:2 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[3]} onClick={() => this.setState({ isOpen: true, photoIndex:3 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[4]} onClick={() => this.setState({ isOpen: true, photoIndex:4 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[5]} onClick={() => this.setState({ isOpen: true, photoIndex:5 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[6]} onClick={() => this.setState({ isOpen: true, photoIndex:6 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[7]} onClick={() => this.setState({ isOpen: true, photoIndex:7 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[8]} onClick={() => this.setState({ isOpen: true, photoIndex:8 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[9]} onClick={() => this.setState({ isOpen: true, photoIndex:9 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[10]} onClick={() => this.setState({ isOpen: true, photoIndex:10 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[11]} onClick={() => this.setState({ isOpen: true, photoIndex:11 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[12]} onClick={() => this.setState({ isOpen: true, photoIndex:12 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[13]} onClick={() => this.setState({ isOpen: true, photoIndex:13 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[14]} onClick={() => this.setState({ isOpen: true, photoIndex:14 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[15]} onClick={() => this.setState({ isOpen: true, photoIndex:15 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[16]} onClick={() => this.setState({ isOpen: true, photoIndex:16 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[17]} onClick={() => this.setState({ isOpen: true, photoIndex:17 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[18]} onClick={() => this.setState({ isOpen: true, photoIndex:18 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[19]} onClick={() => this.setState({ isOpen: true, photoIndex:19 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[20]} onClick={() => this.setState({ isOpen: true, photoIndex:20 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[21]} onClick={() => this.setState({ isOpen: true, photoIndex:21 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        {/* <img className="gallery-img" src={dir + "/gallery/Ferrari/Ferrari_2.jpg"} onClick={() => this.setState({ isOpen: true, photoIndex:1 })}></img> */}
                    </Col>
                    <Col className="gallery-col">
                        {/* <img className="gallery-img" src={dir + "/gallery/Ferrari/Ferrari_3.jpg"} onClick={() => this.setState({ isOpen: true, photoIndex:2 })}></img> */}
                    </Col>
                </Row>
            </Tab>
            <Tab eventKey="aston-martin" title="ASTON MARTIN" className="gallery-tab">
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[22]} onClick={() => this.setState({ isOpen: true, photoIndex:22 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[23]} onClick={() => this.setState({ isOpen: true, photoIndex:23 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[24]} onClick={() => this.setState({ isOpen: true, photoIndex:24 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[25]} onClick={() => this.setState({ isOpen: true, photoIndex:25 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[26]} onClick={() => this.setState({ isOpen: true, photoIndex:26 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[27]} onClick={() => this.setState({ isOpen: true, photoIndex:27 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[28]} onClick={() => this.setState({ isOpen: true, photoIndex:28 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[29]} onClick={() => this.setState({ isOpen: true, photoIndex:29 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[30]} onClick={() => this.setState({ isOpen: true, photoIndex:30 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[31]} onClick={() => this.setState({ isOpen: true, photoIndex:31 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[32]} onClick={() => this.setState({ isOpen: true, photoIndex:32 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[33]} onClick={() => this.setState({ isOpen: true, photoIndex:33 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[34]} onClick={() => this.setState({ isOpen: true, photoIndex:34 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[35]} onClick={() => this.setState({ isOpen: true, photoIndex:35 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[36]} onClick={() => this.setState({ isOpen: true, photoIndex:36 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[37]} onClick={() => this.setState({ isOpen: true, photoIndex:37 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[38]} onClick={() => this.setState({ isOpen: true, photoIndex:38 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[39]} onClick={() => this.setState({ isOpen: true, photoIndex:39 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[40]} onClick={() => this.setState({ isOpen: true, photoIndex:40 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[41]} onClick={() => this.setState({ isOpen: true, photoIndex:41 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[42]} onClick={() => this.setState({ isOpen: true, photoIndex:42 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[43]} onClick={() => this.setState({ isOpen: true, photoIndex:43 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[44]} onClick={() => this.setState({ isOpen: true, photoIndex:44 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[45]} onClick={() => this.setState({ isOpen: true, photoIndex:45 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[46]} onClick={() => this.setState({ isOpen: true, photoIndex:46 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[47]} onClick={() => this.setState({ isOpen: true, photoIndex:47 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[48]} onClick={() => this.setState({ isOpen: true, photoIndex:48 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[49]} onClick={() => this.setState({ isOpen: true, photoIndex:49 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[50]} onClick={() => this.setState({ isOpen: true, photoIndex:50 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[51]} onClick={() => this.setState({ isOpen: true, photoIndex:51 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[52]} onClick={() => this.setState({ isOpen: true, photoIndex:52 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        {/* <img className="gallery-img" src={dir + "/gallery/Ferrari/Ferrari_2.jpg"} onClick={() => this.setState({ isOpen: true, photoIndex:1 })}></img> */}
                    </Col>
                    <Col className="gallery-col">
                        {/* <img className="gallery-img" src={dir + "/gallery/Ferrari/Ferrari_3.jpg"} onClick={() => this.setState({ isOpen: true, photoIndex:2 })}></img> */}
                    </Col>
                </Row>
            </Tab>
            <Tab eventKey="mclaren" title="MCLAREN" className="gallery-tab">
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[53]} onClick={() => this.setState({ isOpen: true, photoIndex:53 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[54]} onClick={() => this.setState({ isOpen: true, photoIndex:54 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[55]} onClick={() => this.setState({ isOpen: true, photoIndex:55 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[56]} onClick={() => this.setState({ isOpen: true, photoIndex:56 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[57]} onClick={() => this.setState({ isOpen: true, photoIndex:57 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[58]} onClick={() => this.setState({ isOpen: true, photoIndex:58 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[59]} onClick={() => this.setState({ isOpen: true, photoIndex:59 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[60]} onClick={() => this.setState({ isOpen: true, photoIndex:60 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[61]} onClick={() => this.setState({ isOpen: true, photoIndex:61 })}></img>
                    </Col>
                </Row>
            </Tab>
            <Tab eventKey="porsche" title="PORSCHE" className="gallery-tab">
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[62]} onClick={() => this.setState({ isOpen: true, photoIndex:62 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[63]} onClick={() => this.setState({ isOpen: true, photoIndex:63 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[64]} onClick={() => this.setState({ isOpen: true, photoIndex:64 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[65]} onClick={() => this.setState({ isOpen: true, photoIndex:65 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[66]} onClick={() => this.setState({ isOpen: true, photoIndex:66 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[67]} onClick={() => this.setState({ isOpen: true, photoIndex:67 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[68]} onClick={() => this.setState({ isOpen: true, photoIndex:68 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[69]} onClick={() => this.setState({ isOpen: true, photoIndex:69 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[70]} onClick={() => this.setState({ isOpen: true, photoIndex:70 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[71]} onClick={() => this.setState({ isOpen: true, photoIndex:71 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[72]} onClick={() => this.setState({ isOpen: true, photoIndex:72 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[73]} onClick={() => this.setState({ isOpen: true, photoIndex:73 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[74]} onClick={() => this.setState({ isOpen: true, photoIndex:74 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[75]} onClick={() => this.setState({ isOpen: true, photoIndex:75 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[76]} onClick={() => this.setState({ isOpen: true, photoIndex:76 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[77]} onClick={() => this.setState({ isOpen: true, photoIndex:77 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[78]} onClick={() => this.setState({ isOpen: true, photoIndex:78 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[79]} onClick={() => this.setState({ isOpen: true, photoIndex:79 })}></img>
                    </Col>
                </Row>
            </Tab>
            <Tab eventKey="misc" title="MISCELLANEOUS" className="gallery-tab">
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[80]} onClick={() => this.setState({ isOpen: true, photoIndex:80 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[81]} onClick={() => this.setState({ isOpen: true, photoIndex:81 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[82]} onClick={() => this.setState({ isOpen: true, photoIndex:82 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[83]} onClick={() => this.setState({ isOpen: true, photoIndex:83 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[84]} onClick={() => this.setState({ isOpen: true, photoIndex:84 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[85]} onClick={() => this.setState({ isOpen: true, photoIndex:85 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[86]} onClick={() => this.setState({ isOpen: true, photoIndex:86 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[87]} onClick={() => this.setState({ isOpen: true, photoIndex:87 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[88]} onClick={() => this.setState({ isOpen: true, photoIndex:88 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[89]} onClick={() => this.setState({ isOpen: true, photoIndex:89 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[90]} onClick={() => this.setState({ isOpen: true, photoIndex:90 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[91]} onClick={() => this.setState({ isOpen: true, photoIndex:91 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[92]} onClick={() => this.setState({ isOpen: true, photoIndex:92 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[93]} onClick={() => this.setState({ isOpen: true, photoIndex:93 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[94]} onClick={() => this.setState({ isOpen: true, photoIndex:94 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[95]} onClick={() => this.setState({ isOpen: true, photoIndex:95 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[96]} onClick={() => this.setState({ isOpen: true, photoIndex:96 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[97]} onClick={() => this.setState({ isOpen: true, photoIndex:97 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[98]} onClick={() => this.setState({ isOpen: true, photoIndex:98 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[99]} onClick={() => this.setState({ isOpen: true, photoIndex:99 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[100]} onClick={() => this.setState({ isOpen: true, photoIndex:100 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[101]} onClick={() => this.setState({ isOpen: true, photoIndex:101 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[102]} onClick={() => this.setState({ isOpen: true, photoIndex:102 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[103]} onClick={() => this.setState({ isOpen: true, photoIndex:103 })}></img>
                    </Col>
                </Row>
                <Row className="gallery-row">
                    <Col className="gallery-col">
                        <img className="gallery-img" alt="gallery-img" src={images[104]} onClick={() => this.setState({ isOpen: true, photoIndex:104 })}></img>
                    </Col>
                    <Col className="gallery-col">
                        {/* <img className="gallery-img" src={images[81]} onClick={() => this.setState({ isOpen: true, photoIndex:81 })}></img> */}
                    </Col>
                    <Col className="gallery-col">
                        {/* <img className="gallery-img" src={images[82]} onClick={() => this.setState({ isOpen: true, photoIndex:82 })}></img> */}
                    </Col>
                </Row>
                
            </Tab>

            
            </Tabs>
            {isOpen && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                    this.setState({
                        photoIndex: (photoIndex + images.length - 1) % images.length,
                    })
                    }
                    onMoveNextRequest={() =>
                    this.setState({
                        photoIndex: (photoIndex + 1) % images.length,
                    })
                    }
                />
                )}
            </Container>
            </>
        );
    }
}

export default GalleryPage