import React from 'react'

import ContactForm from '../components/contactform';
import QuickLinks from '../components/quicklinks';
class ContactPage extends React.Component {
    render() {
        return (
            <>
            <div className="contact-main-display contact-mobile-header-img">
                <div className="home-main-display-text">
                    <h1>Contact</h1>
                </div>
            </div>
            <div className="contact-form-contact">
            <ContactForm />
            </div>
            <QuickLinks />

            </>
        );
    }
}

export default ContactPage