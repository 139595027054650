import React from 'react'
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import FiveStar from '../img/Review5stars.svg'

class testimonialPage extends React.Component {
    render() {
        return (
            <>
            <div className="testimonials-main-display testimonial-mobile-header-img">
                <div className="home-main-display-text">
                    <h1>Testimonials</h1>
                </div>
            </div>

            <Container fluid={true} className="testimonials-content-container">
                <Row className="testimonials-containing-row">
                    <Col className="testimonials-wrapper">
                        <Row className="testimonials-row">
                            <Col className="testimonials-col">
                            <p>“The end results were pretty spectacular – I was so happy with the car after. I can unreservedly recommend him.”</p>
                            <img src={FiveStar} className="fivestar" alt="" />
                            </Col>
                            <Col className="testimonials-col">
                            <p>“Over the years Francis has used his magic on a number of our cars - he is just simply a STAR!  Dependable, trustworthy, professional and the best - highly recommended. “</p>
                            <img src={FiveStar} className="fivestar" alt="" />
                            </Col>
                        </Row>
                        <Row className="testimonials-row">
                            <Col className="testimonials-col">
                            <p>“I have no reservations about recommending Francis to anybody who has a pride and passion about the finish on their car. The best I have ever experienced.“</p>
                            <img src={FiveStar} className="fivestar" alt="" />
                            </Col>
                            <Col className="testimonials-col">
                            <p>“He is a dedicated, hard working, decent guy and spent the best part of two days making my car look pristine again."</p>
                            <img src={FiveStar} className="fivestar" alt="" />
                            </Col>
                        </Row>
                    {/* <button className="submit-button testimonial-button"> SEE ALL TESTEMONIALS </button> */}
                    </Col>
                    <Col className="testimonials-content">
                        <div className="testimonials-text">
                        <h2>“There seems to be no limit to his knowledge and the attention to top quality workmanship.“</h2>
                        <a href="/contact">
                            <button className="mainbutton testimonial-service-button">BOOK NOW</button>
                        </a>
                        </div>
                    </Col>
                </Row>
            </Container>
            </>
        );
    }
}

export default testimonialPage