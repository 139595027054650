import React from 'react'
import Accordion from '../components/accordion';

import { Container } from 'react-bootstrap';

import QuickLinks from '../components/quicklinks';
import ContactForm from '../components/contactform';
class FaqPage extends React.Component {
    render() {
        return (
            <>
            <div className="faq-main-display faq-mobile-header-img">
                <div className="home-main-display-text">
                    <h1>FAQ's</h1>
                </div>
            </div>
            <Container fluid={true} className="faq-accordion-container">
            <Accordion title="Why use francis and emerald detailing?">
            I am one of the UK’s most experienced detailers, with well over a decade of detailing experience. I am also the most experienced Aston Martin detailer in the country. I understand that almost every car that I work on is someones pride and joy and is treated accordingly. It doesn’t matter if the car is worth £20k or £2,000,000, it will be treated the same and with the upmost care and respect.
            </Accordion>
            <Accordion title="Is it worth detailing a new car?">
            Absolutely. All new cars need some form of machine polishing to remove swirl marks and imperfections that have been caused during the transportation or dealer prep. Hand finished cars such as Aston Martin, McLaren and Ferrari often need extensive work to remove all the sanding marks and imperfections from the paint process. Then protecting the car from day one with a ceramic coating helps to keep it looking like new for years to come.
            </Accordion>
            <Accordion title="What is paint correction?">
            Paint correction, or machine polishing, is the term for the mechanical polishing of the paintwork using a machine to remove defects in the paintwork, such as swirl marks, sanding marks and scratches. It makes the single biggest difference to the overall look of the car and no amount of waxing can replicate the results achieved by machine polishing.
            </Accordion>
            <Accordion title="Is machine polishing safe?">
            Yes. The paint is measured all over the car before any polishing is carried out. This allows me to see if any panels have been painted/repaired and to see if there are areas with low paint readings. If there are, then extra care is taken in these areas. Only the minimum amount of clear coat needed to safely remove the paint defects is removed. Usually only a few microns, depending on the level of polishing required. Sometimes it is not safe to remove certain scratches. In these cases, the client will be advised accordingly.
            </Accordion>
            <Accordion title="Are you insured?">
            Yes, I am fully insured. Both for damage to the vehicle while being worked on and public liability. Certificates can be shown if requested.
            </Accordion>
            <Accordion title="Are you mobile or unit based?">
            I am and always have been, a mobile detailer. My client base is so spread around the country that having a unit based in one location wouldn’t make sense. Plus, clients find it more convenient for me to come to them. All I require is a hose point and a power point.
            </Accordion>
            <Accordion title="Are you tied to one brand?">
            No, I’m not tied to one brand, but instead affiliate myself with the best brands on the market that make the best products for the specific application. I have spent thousands over the years trying out new products and know what works best.
            </Accordion>
            <Accordion title="Is a ceramic coating worth it?">
            Yes, especially after any form of paint correction. A ceramic coating/sealant will form a stronger layer of defence against the elements, bird lime and help prevent against wash marring and swirls marks. No wax can offer the same level of protection as a sealant can or last anywhere near as long. Not all coatings are equal though. While many of the coatings claim to last anything up to 9 years, I would suggest to people to have the car polished and topped up every 2-3 years depending on use.
            </Accordion>
            <Accordion title="Why are cermaic coatings so expensive?">
            When it comes to coatings/sealants, you get what you pay for. There are so many cheap and ineffective coatings on the market. Always find out what is being applied to your car. You need to know more than just the brand name too. For example, Gtechniq offers a range of coatings, but I would only use the pro only Crystal Serum Ultra. Other detailers might tell you they are applying a Gtechniq sealant to your car, but only use their C1 coating, which wont last anywhere near as long or provide the same level of protection. For this reason, always check.
            </Accordion>
            <Accordion title="Do you work weekends?">
            I am available 7 days a week and typically work from 8am-9pm
            </Accordion>
            <Accordion title="Can you work in confidence?">
            Yes. Many of my clients are high net worth individuals and do not want anyone to know what they own and where they live. I do not discuss clients cars with anyone else and any pictures shown on social media or on my website has been approved by the owner and always with the number plates blanked out.
            </Accordion>
            <Accordion title="What is the difference between detailing and valeting?">
            Put simply, the main difference is paint correction, level of quality with the products used to clean your car, time and safety. A valeter will in many cases do a good job of cleaning your car, but in order to make any money, they have to do it in the quickest and most cost effective way. This often means using cheaper, harsher chemicals and methods of drying the car that are not as soft and safe as they should be. This is often due to the volume of cars they need to get through. There are too many valeters out there claiming to be detailers, but all they do is wash cars and try to get higher end clients by using the name detailing. Put simply, unless you do proper paint correction and not just applying a wax or polish with a machine, you are not a detailer.
            </Accordion>
            <Accordion title="Is it worth having my car detailed prior to selling it?">
            In most cases, detailing a car prior to selling it will get you a higher sale price, which will more often than not, offset the price of the detail. An Enhancement detail is usually enough to get the car ready for sale.
            </Accordion>
            </Container>
            <QuickLinks />
            <div className="contact-form-home">
                <ContactForm />
            </div>
            </>
        );
    }
}

export default FaqPage