import React from "react";
import Container from "react-bootstrap/Container";
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import ContactForm from '../components/contactform'
import QuickLinks from "../components/quicklinks";

import ServiceImg from '../img/buffing.png'

class HomePage extends React.Component {
    render() {
        return (
            <>
            <div className="home-main-display home-mobile-header-img">
                <div className="home-main-display-text">
                    <h1>Premier Mobile</h1>
                    <h1>Vehicle Detailing</h1>
                </div>
            </div>
            <div className="home-contact-banner m-0">
                <div className="home-contact-banner-text">
                <p className="small-text-style-0 d-inline">GET IN TOUCH:</p>&nbsp;
                <p className="small-text-style-1 d-inline">CALL</p>&nbsp;
                <p className="small-text-style-2 d-inline"><a href="tel:07909541048">07909 541048</a></p>&nbsp;
                <p className="small-text-style-1 d-inline">OR EMAIL</p>&nbsp;
                <p className="small-text-style-2 d-inline"><a href="mailto:hello@emerald-detailing.com">HELLO@EMERALD-DETAILING.COM</a></p>
                </div>
            </div>
            <Container fluid={true} className="home-service-content-container">
                <Row>
                    <Col className="home-service-img-container">
                    <img className="home-service-img" width="" height="" src={ServiceImg} alt="buffing.png"></img>
                    </Col>
                    <Col className="home-service-content">
                        <div className="home-service-text">
                        <h2>Our Services</h2>
                        <br />
                        <p>I specialise in paint correction (machine polishing), but no matter which service you are looking for, the aim is still the same. To thoroughly clean, decontaminate and make sure the car looks as good as it can.</p>
                        <br />
                        <p>No matter what option you decide to go for, the vehicle will receive the same decontamination process before choosing which service option is the best for you and your vehicle.</p>
                        <a href="/services">
                            <button className="mainbutton">SEE ALL SERVICES</button>
                        </a>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="home-testimonial-banner m-0">
                <div className="home-testimonial-text">
                    <h2>“Francis was fantastic and the attention to detail was astounding! <br /> Would definitely recommend”</h2>
                    <a href="/testimonials">
                    <button className="mainbutton"> MORE TESTIMONIALS </button>
                    </a>
                </div>
            </div>
            <QuickLinks />
            <div className="contact-form-home">
                <ContactForm />
            </div>
            </>
        )
    }
}

export default HomePage