import React from "react";
import { Container } from "react-bootstrap";

class FormSubmitSuccess extends React.Component {
    render() {
        return(
            <>
            <Container fluid="true" className="form-success-container" id="toggle-class">
                <h2>Thank you</h2>
                <h2>for your enquiry</h2>
                <br />
                <p>I will respond to your message as soon as possible, usually within 24 hours or less.</p>
                <br />
                <p>Speak soon!</p>
                <br />
                <p>Francis.</p>
                <button className="mainbutton" onClick={() => window.location.reload(false)}>CLOSE & CONTINUE</button>
            </Container>
            </>
        )
    }
}

export default FormSubmitSuccess