import React from "react";

import { Container } from "react-bootstrap";

class PageNotFound extends React.Component {
    render() {
        return(
            <>
            <Container fluid={true} className="not-found">
                <div className="not-found-content">
                <h1>404! Page Not Found!</h1>
                <br />
                <a href="/">
                <button className="mainbutton">Go to Home</button>
                </a>
                </div>
            </Container>
            </>
        )
    }
}

export default PageNotFound