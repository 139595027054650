import React from 'react'
import { Card, Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import $ from 'jquery'

import gtechniqimg from '../img/gtechniq.png'
import titanimg from '../img/titan.png'
import kamikazeimg from '../img/kamikaze.png'

class CeramicCoatingPage extends React.Component {

    hide1() {
        $('#hide1').toggle();
        $('#hide1btn').toggleClass('show-less-button')
    }

    hide2() {
        $('#hide2').toggle();
        $('#hide2btn').toggleClass('show-less-button')
    }

    hide3() {
        $('#hide3').toggle();
        $('#hide3btn').toggleClass('show-less-button')
    }

    render() {
        return (
            <>
            <div className="ceramics-main-display ceramics-mobile-header-img">
                <div className="home-main-display-text">
                    <h1>Ceramic Coating</h1>
                </div>
            </div>
            <Container className="ceramics-accreditation">
                <h2>Ceramic Coating Options</h2>
                <br />
                <p>First thing you need to know is that not all ceramic coatings or sealants are equal. It is important that you know what you are paying for. I will only use the Best coatings offered by a manufacturer. I am not tied to one manufacturer and am always looking at new and updated coatings to make sure I give my clients the best. </p>
                <p>Currently, I am authorised by:</p>
                <br />
                <div>
                    <img src={gtechniqimg} className="ceramics-accreditation-img" alt="gtechniq-img"></img>
                    <img src={titanimg} className="ceramics-accreditation-img" alt="titan-img"></img>
                    <img src={kamikazeimg} className="ceramics-accreditation-img" alt="kamikaze-img"></img>
                </div>
            </Container>
            <Container fluid={true} className="ceramics-options">
            <Row className="ceramics-card-carousel">
                <Card className="h-card-style3">
                    <Card.Body className="h-card-top-style2">
                        <h4>GTECHNIQ</h4>
                        <p>Crystal Serum Ultimate</p>
                        <h2>£300</h2>
                    </Card.Body>
                    <Card.Body className="card-style3">
                        <p>Crystal Serum Ultimate (CSU) is the Gtechniq flagship coating. It is a single layer coating that offers fantastic protection to your paint against airborne contamination and wash marring. </p>
                        <div id="hide1">
                        <p>Due to the chemical makeup of CSU, it cannot come into contact with water during the application process, so any drips from trim or wing mirrors etc will cause smearing on application. This means that CSU cannot be applied on the same day as the car is washed.</p>
                        </div>
                        <button id="hide1btn" className="show-more-button-style2" onClick={this.hide1.bind(this)}></button>
                    </Card.Body>
                    <a href="/contact">
                    <button className="submit-button ceramics-book-button">BOOK NOW</button>
                    </a>
                </Card>
                <Card className="h-card-style3">
                    <Card.Body className="h-card-top-style2">
                        <h4>GTECHNIQ</h4>
                        <p>Crystal Serum Black</p>
                        <h2>£450</h2>
                    </Card.Body>
                    <Card.Body className="card-style3">
                        <p>This is the combination of CSU and Exo. Exo in itself is extremely hydrophobic, but doesn’t offer the same level of protection as you would get a standalone sealant, as its main job is to bead and repel water. When it is used as a top coat on CSU, you get the best of both worlds.</p>
                    </Card.Body>
                    <a href="/contact">
                    <button className="submit-button ceramics-book-button">BOOK NOW</button>
                    </a>
                </Card>
                <Card className="h-card-style3">
                    <Card.Body className="h-card-top-style2">
                        <h4>KAMIKAZE</h4>
                        <p>Enrei Coat</p>
                        <h2>£450</h2>
                    </Card.Body>
                    <Card.Body className="card-style3">
                        <p>Enrei is the Kamikaze Collection flagship coating. It is a 2 layer, semi-self healing coating. This means that if you pour very hot water over a panel that’s been coated, it will semi-self heal and remove light wash marring and swirl marks. It also has excellent water behaviour. </p>
                    </Card.Body>
                    <a href="/contact">
                    <button className="submit-button ceramics-book-button">BOOK NOW</button>
                    </a>
                </Card>
            </Row>

            <Row className="ceramics-card-carousel">
                <Card className="h-card-style3">
                    <Card.Body className="h-card-top-style2">
                        <h4>TITAN COATINGS</h4>
                        <p>Zeus</p>
                        <h2>£250</h2>
                    </Card.Body>
                    <Card.Body className="card-style3">
                        <p>Zeus uses Hyperquartz Carbon technology and is one of the most advanced coatings on the market. It creates a candy like gloss and has the ability to self level. Zeus can also be layered on its own or on top of Titan Vulcan.</p>
                    </Card.Body>
                    <a href="/contact">
                    <button className="submit-button ceramics-book-button">BOOK NOW</button>
                    </a>
                </Card>
                <Card className="h-card-style3">
                    <Card.Body className="h-card-top-style2">
                        <h4>TITAN COATINGS</h4>
                        <p>Vulcan</p>
                        <h2 className="d-inline">£300</h2>
                        <p className="d-inline"> per layer</p>
                    </Card.Body>
                    <Card.Body className="card-style3">
                        <p>Vulcan uses rubber membrane technology and offers excellent water repellency. It has near permanent hydrophobicity with 2 layers and is extremely resistant to chemicals and water spotting. </p>
                        <div id="hide2">
                        <p>It also absorbs abrasions, making it resilient to wash marring and swirl marks. It can also be topped with Zeus for improved looks and even more protection.</p>
                        </div>
                        <button id="hide2btn" className="show-more-button-style2" onClick={this.hide2.bind(this)}></button>
                    </Card.Body>
                    <a href="/contact">
                    <button className="submit-button ceramics-book-button">BOOK NOW</button>
                    </a>
                </Card>
                <Card className="h-card-style3">
                    <Card.Body className="h-card-top-style2">
                        <h4>TITAN COATINGS</h4>
                        <p>PX 10</p>
                        <h2 className="d-inline">£200</h2>
                        <p className="d-inline"> per layer</p>
                    </Card.Body>
                    <Card.Body className="card-style3">
                        <p>PX10 works in much the same way as Vulcan, but its main purpose is to be a base coat to either Vulcan or Zeus.  </p>
                        <div id="hide3">
                        <p>PX10 doesn’t have the same levels of gloss or water repellency as the rest of the Titan range, but it’s the perfect base for cars with soft clear coat. It averages 3 microns of extra thickness on the cars paint per layer, with a max of 3 layers. This means you are far less likely to get swirl marks in the paint.</p>
                        </div>
                        <button id="hide3btn" className="show-more-button-style2" onClick={this.hide3.bind(this)}></button>
                    </Card.Body>
                    <a href="/contact">
                    <button className="submit-button ceramics-book-button">BOOK NOW</button>
                    </a>
                </Card>
            </Row>
            <div className="ceramics-extras">
            <h2>Additional Extras</h2>
            <Row className="ceramics-extras-row">
                <Col className="ceramics-extras-content">
                <h4>WHEEL COATINGS</h4>
                <p>Kamikaze Collection Stance rim coat.</p>
                <p>Wheel faces £150</p>
                <p>Complete coverage £250</p>
                </Col>
                <Col className="ceramics-extras-content">
                <h4>GLASS SEALANT</h4>
                <p>Gtechniq smart vision £75</p>
                </Col>
            </Row>
            </div>
            </Container>
            </>
        );
    }
}

export default CeramicCoatingPage