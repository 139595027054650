import { React, useState } from "react";
import $ from 'jquery'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import axios from 'axios'

import FormSubmitSuccess from './contactformsuccess'

const ContactForm = () => {
    const [state, setState] = useState({
        name: "",
        email: "",
        number: "",
        message: ""
      });

      const [result, setResult] = useState(null);
    
      const checker = () => {
        if (state.name === "") {
          $('#name').toggleClass("err")
        }
        
        if (state.email === "") {
          $('#email').toggleClass("err")
        } 

        if (state.number === "") {
          $('#tel').toggleClass("err")
        }
        
        if (state.message === "") {
          $('#message').toggleClass("err")
        }
        }

        const errchecker = () => {
        if ($('#name').hasClass("err")) {
          $('#name').toggleClass("err")
        }

        if ($('#email').hasClass("err")) {
          $('#email').toggleClass("err")
        }

        if ($('#tel').hasClass("err")) {
          $('#tel').toggleClass("err")
        }

        if ($('#message').hasClass("err")) {
          $('#message').toggleClass("err")
        }
        }

    
      const sendEmail = event => {
        event.preventDefault();
        if (state.name === "" || state.email === "" || state.number === "" || state.message === "")  {
          checker();
        } else {
        axios
          .post('https://www.emerald-detailing.com:444/send/', { ...state })
          .then(response => {
            setResult(response.data);
            setState({
              name: "",
              email: "",
              number: "",
              message: ""
            });
          })
         .catch(() => {
           setResult({ success: false, message: 'Something went wrong. Try again later'});
       });

       $(".form-container").toggle()

        }}
    
     const onInputChange = event => {
       const { name, value } = event.target;
    
       setState({
         ...state,
        [name]: value
       });
    };
    
        return( 
            <>
            {result && (
              <FormSubmitSuccess className={`${result.success ? 'form-success' : 'form-fail'}`}/>
              )}
        <Container className="form-container" fluid={true}>
            <Row>
                <Col className="form-col">
            <form className="contact-form" id="p" onSubmit={sendEmail}>
                <div className="form-field-container">
                <input className="form-field" type="text" name="name" id="name" placeholder="Name" value={state.name} onChange={onInputChange} onInput={errchecker}/>
                </div>
                <div className="form-field-container">
                <input className="form-field email" type="email" name="email" id="email" placeholder="Email Address" value={state.email} onChange={onInputChange} onInput={errchecker}/>
                </div>
                <div className="form-field-container">
                <input className="form-field" type="text" name="number" id="tel" placeholder="Phone Number" value={state.number} onChange={onInputChange} onInput={errchecker}/>
                </div>
                <div className="form-field-container">
                <textarea className="form-textarea" type="textarea" name="message" id="message" placeholder="Write Message" value={state.message} onChange={onInputChange} onInput={errchecker}/>
                </div>
                <button className="form-submit-button" type="submit"><p className="form-submit-text">SUBMIT</p></button>
            </form>
                </Col>
                <Col>
                <div className="form-descriptor">
                    <h3 className="form-descriptor-title">Quick Contact</h3>
                    <br />
                    <p className="form-descriptor-text">Please feel free to call or email with your request, alternatively just fill in the quick contact form and we will get back to you within 24 hours.</p>
                    <br />
                    <p className="form-descriptor-title">
                    <a href="tel:07909541048">07909 541048</a>
                    </p>
                    <p className="form-descriptor-title">
                    <a href="mailto:hello@emerald-detailing.com">hello@emerald-detailing.com</a>
                    </p>
                </div>
                </Col>
            </Row>
        </Container>
        </>
        );
    }

export default ContactForm