import React from "react";

class QuickLinks extends React.Component {
    render() {
        return(
            <>
            <div className="quick-links">
                <a href="/faq">
                <div className="quick-link-banner quick-faq-banner">
                    <h3 className="quick-link-banner-text">FAQ's</h3>
                </div>
                </a>
                <a href="/about">
                <div className="quick-link-banner quick-about-banner">
                    <h3 className="quick-link-banner-text">About Us</h3>
                </div>
                </a>
                <a href="/services">
                <div className="quick-link-banner quick-services-banner">
                    <h3 className="quick-link-banner-text">Services</h3>
                </div>
                </a>
            </div>
            </>
        )
    }
}

export default QuickLinks