import React from "react";

const Accordion = ({ title, children }) => {
    const [isOpen, setOpen] = React.useState(false);
    return (
      <div className="accordion-wrapper">
        <div
          className={`accordion-title ${isOpen ? "open" : ""}`}
          onClick={() => setOpen(!isOpen)}
          >
          <div className="accordion-title-inner">
          {title}
          </div>
          <div
          className={`accordion-state-img ${isOpen ? "open" : ""}`}
          onClick={() => setOpen(!isOpen)}
          ></div>
        </div>
        <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
          <div className="accordion-content">{children}</div>
        </div>
      </div>
    );
  }

export default Accordion